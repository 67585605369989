import Cookies from "js-cookie";
import { sleep } from "@tawenda-npm/tawenda-utils";

export class LikeActor {
  likeUrl: string;
  starsContainer: HTMLElement;

  classLiked: string;
  classAlreadyLiked: string;

  constructor(private button: HTMLButtonElement) {
    this.likeUrl = this.button.dataset.likeActor;
    this.classLiked = this.button.dataset.likeClassLiked;
    this.classAlreadyLiked = this.button.dataset.likeClassAlreadyLiked;

    this.button.addEventListener("click", async (e: Event) => {
      e.preventDefault();
      await this.like();
    });
  }

  private async like(): Promise<void> {
    const req = await fetch(this.likeUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "POST",
    });

    if (req.ok) {
      const response = await req.json();
      if (response.success) {
        if (response.already_vote) {
          await this.error();
        } else {
          await this.liked();
        }
      }
    }
  }

  private async liked(): Promise<void> {
    if (!this.button.classList.contains(this.classLiked)) {
      this.button.classList.add(this.classLiked);
      await sleep(3000);
      this.button.classList.remove(this.classLiked);
    }
  }

  private async error(): Promise<void> {
    if (this.button.classList.contains(this.classLiked))
      this.button.classList.remove(this.classLiked);

    if (!this.button.classList.contains(this.classAlreadyLiked)) {
      this.button.classList.add(this.classAlreadyLiked);
      await sleep(3000);
      this.button.classList.remove(this.classAlreadyLiked);
    }
  }
}
