export class AjaxActors {
  private readonly apiUrl: string;
  private canLoad = true;

  constructor(private input: HTMLInputElement, private target: HTMLElement) {
    this.apiUrl = input.dataset.apiUrl;

    this.input.addEventListener("click", async (e: Event) => {
      await this.load();
    });

    this.input.addEventListener("keyup", async (e: Event) => {
      await this.load();
    });
  }

  private async load(): Promise<void> {
    if (!this.canLoad) return;

    this.canLoad = false;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("jm-parent-url", window.location.pathname);

    let apiUrl: string = this.apiUrl;
    if (this.input.value !== "") {
      apiUrl = `${apiUrl}${this.input.value.replace(" ", "-").toLowerCase()}`;
    }

    const req = await fetch(apiUrl, {
      headers: requestHeaders,
    });
    if (req.ok) {
      this.showResults(await req.text());
    }
    this.canLoad = true;
  }

  private showResults(html: string) {
    this.target.innerHTML = html;
  }
}
