const affiliationPixel = () => {
  const queryString = window.location.search;
  const query: Record<string, string> = {};

  queryString
    .substring(1)
    .split("&")
    .forEach((p) => {
      const pair = p.split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    });

  if (query.t) {
    const img: HTMLImageElement = document.createElement("img");

    img.src = `https://straceo.com/affiliate/?next=/&t=${query.t}`;
    img.width = 1;
    img.height = 1;

    document.body.append(img);
  }
};

export default affiliationPixel;
