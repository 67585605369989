import { tns } from "tiny-slider";

const carouselBanners = () => {
  document
    .querySelectorAll("[data-carousel-banners]")
    .forEach((el: HTMLElement) => {
      tns({
        container: el,
        items: 1,
        slideBy: "page",
        autoplay: true,
        nav: false,
        controls: true,
        controlsContainer: el
          .closest("[data-carousel-banners-wrapper]")
          .querySelector(".carousel-controls"),
        autoplayButtonOutput: false,
        mouseDrag: true,
      });
    });
};

export default carouselBanners;
