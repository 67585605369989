import { toggleClass } from "@tawenda-npm/tawenda-utils";

const PayWallInit = () => {
  const payWall: HTMLElement | undefined =
    document.querySelector("[data-paywall]");

  if (payWall) {
    document
      .querySelectorAll("[data-show-payments]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", (e: Event) => {
          e.preventDefault();
          toggleClass(payWall);
        });
      });

    payWall
      .querySelectorAll("[data-paywall-close]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", (e: Event) => {
          e.preventDefault();
          toggleClass(payWall);
        });
      });

    payWall
      .querySelectorAll("[data-show-offer]")
      .forEach((el: HTMLInputElement) => {
        el.addEventListener("click", (e: Event) => {
          payWall
            .querySelectorAll("[data-offer]")
            .forEach((offer: HTMLElement) => {
              if (offer.classList.contains(el.dataset.toggleClass)) {
                offer.classList.remove(el.dataset.toggleClass);
              }
            });
          toggleClass(el);
        });
      });
  }
};

export default PayWallInit;
