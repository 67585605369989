import * as pdfjsLib from "pdfjs-dist";
import { PDFDocumentProxy } from "pdfjs-dist";
import { toggleClass } from "@tawenda-npm/tawenda-utils";

interface PdfViewerOptions {
  pdfUrl: string;
  workerSrc: string;

  container: HTMLElement;
  containerLoading: HTMLElement;
  containerOverlay: HTMLElement;
  containerError: HTMLElement;
  prevButton: HTMLButtonElement;
  nextButton: HTMLButtonElement;
  targetPageNumGoTo: HTMLInputElement;
  targetPageCount: HTMLElement;

  scale?: number;
  currentPage?: number;

  isMobile: boolean;
  isExtract: boolean;
  isSoft: boolean;
}

export class PdfViewer {
  private readonly pdfUrl: string;
  private readonly workerSrc: string;

  private pdfDoc: PDFDocumentProxy;

  private readonly container: HTMLElement;
  private readonly containerLoading: HTMLElement;
  private readonly containerOverlay: HTMLElement;
  private readonly containerError: HTMLElement;
  private readonly prevButton: HTMLButtonElement;
  private readonly nextButton: HTMLButtonElement;
  private readonly targetPageNumGoTo: HTMLInputElement;
  private readonly targetPageCount: HTMLElement;

  protected numPages = 1;
  protected currentPage: number;
  protected defaultScale: number;
  protected scale: number;
  protected pageRendering = false;
  protected pageNumPending = null;

  protected isMobile: boolean;
  protected isExtract: boolean;
  protected isSoft: boolean;

  constructor(private options: PdfViewerOptions) {
    this.pdfUrl = options.pdfUrl;
    this.workerSrc = options.workerSrc;
    pdfjsLib.GlobalWorkerOptions.workerSrc = this.workerSrc;

    this.container = options.container;
    this.containerLoading = options.containerLoading;
    this.containerOverlay = options.containerOverlay;
    this.containerError = options.containerError;
    this.prevButton = options.prevButton;
    this.nextButton = options.nextButton;
    this.targetPageNumGoTo = options.targetPageNumGoTo;
    this.targetPageCount = options.targetPageCount;

    this.defaultScale = options.scale || 1;
    this.scale = this.defaultScale;

    this.currentPage = options.currentPage || 1;

    this.isMobile = options.isMobile;
    this.isExtract = options.isExtract;
    this.isSoft = options.isSoft;
  }

  public async init(): Promise<any> {
    const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
    loadingTask.promise
      .then(async (doc: PDFDocumentProxy) => {
        this.pdfDoc = doc;
        this.numPages = this.pdfDoc.numPages;
        await this.renderPages();
        this.initEvents();
      })
      .catch((e) => {
        console.log(`# Error promise loading pdf ${e}`);
      });
  }

  private beforeRender(): void {
    this.containerLoading.classList.remove(
      this.containerLoading.dataset.toggleClass
    );
    document.querySelectorAll("canvas").forEach((c: HTMLCanvasElement) => {
      c.remove();
    });
  }

  private afterRender(): void {
    this.setContextPage();

    const show_overlay =
      (this.isMobile && this.isLastPage()) ||
      (!this.isMobile &&
        (this.currentPage + 1 === this.numPages || this.isLastPage()));
    const canvasElements = document.querySelectorAll("canvas");

    if (this.isExtract) {
      if (!show_overlay) {
        this.containerOverlay.classList.remove(
          this.containerOverlay.dataset.toggleClass
        );
      }

      if (this.isSoft) {
        canvasElements.forEach((c: HTMLCanvasElement) => {
          c.classList.add("nsfw");
        });
      }

      if (show_overlay) toggleClass(this.containerOverlay);
    }

    this.containerLoading.classList.add(
      this.containerLoading.dataset.toggleClass
    );
  }

  private async renderPages(): Promise<void> {
    this.pageRendering = true;
    this.beforeRender();

    if (this.isFirstOrLastPage() || this.isMobile === true) {
      await this.renderPage(this.currentPage, true);
    } else {
      await this.renderPage(this.currentPage, false);
      await this.renderPage(this.currentPage + 1, true);
    }
    this.afterRender();
  }

  private async renderPage(pageNumber: number): Promise<void> {
    const page = await this.pdfDoc.getPage(pageNumber);

    const viewport = page.getViewport({ scale: this.scale });
    const canvas = document.createElement("canvas");

    const renderContext = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    if (this.isMobile) canvas.style.maxWidth = "100%";

    canvas.style.display = "none";

    this.container.appendChild(canvas);

    const renderTask = await page.render(renderContext);
    renderTask.promise.then(async () => {
      this.pageRendering = false;

      if (this.pageNumPending !== null) {
        if (!this.isFirstOrLastPage() || this.isMobile === false)
          this.currentPage = this.pageNumPending + 1;
        else this.currentPage = this.pageNumPending;

        await this.renderPages();
        this.pageNumPending = null;
      }
    });
  }

  private async queueRenderPage(numPage: number): Promise<void> {
    if (this.pageRendering) {
      this.pageNumPending = numPage;
    } else {
      this.currentPage = numPage;
      await this.renderPages();
    }
  }

  private setContextPage(): void {
    this.targetPageNumGoTo.value = this.currentPage.toString();
    this.targetPageCount.innerText = this.numPages.toString();

    this.prevButton.disabled = this.isFirstPage();
    this.nextButton.disabled =
      this.isLastPage() ||
      (!this.isMobile && this.currentPage + 1 === this.numPages);
  }

  private showError(text: string): void {
    this.containerError.innerText = text;
    toggleClass(this.containerError);
  }

  private resetError(): void {
    this.containerError.innerText = "";
    toggleClass(this.containerError);
  }

  private initEvents(): void {
    this.prevButton.addEventListener("click", async (e: Event) => {
      await this._onPrevPage();
    });

    this.nextButton.addEventListener("click", async (e: Event) => {
      await this._onNextPage();
    });

    this.targetPageNumGoTo.addEventListener("keyup", async (e: Event) => {
      if (e.keyCode === 13) {
        await this._onGoToPage();
      }
    });
  }

  private isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  private isLastPage(): boolean {
    return this.currentPage === this.numPages;
  }

  private isFirstOrLastPage(): boolean {
    return this.isFirstPage() || this.isLastPage();
  }

  private async _onPrevPage(): Promise<void> {
    if (this.isFirstPage()) return;

    if (this.currentPage === 2) this.currentPage = 1;
    else if (this.isMobile === true) this.currentPage--;
    else this.currentPage -= 2;

    await this.queueRenderPage(this.currentPage);
  }

  private async _onNextPage(): Promise<void> {
    if (this.isLastPage()) return;

    if (this.isFirstPage() || this.isMobile === true) this.currentPage++;
    else this.currentPage += 2;

    await this.queueRenderPage(this.currentPage);
  }

  private async _onGoToPage(): Promise<void> {
    this.resetError();
    const requestedPage = parseInt(this.targetPageNumGoTo.value, 10);

    if (
      requestedPage < 1 ||
      requestedPage > this.numPages ||
      isNaN(requestedPage)
    ) {
      this.showError("Veuillez sélectionner un numéro de page valide");
      return;
    }

    this.currentPage = requestedPage;
    await this.queueRenderPage(this.currentPage);
  }
}
