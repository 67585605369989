import Cookies from "js-cookie";
import { sleep } from "@tawenda-npm/tawenda-utils";

export class Votes {
  wrapper: HTMLElement;
  voteUrl: string;
  videoId: number;

  starsContainer: HTMLElement;
  votedSuccessElement: HTMLElement;
  votedErrorElement: HTMLElement;

  constructor(private input: HTMLInputElement) {
    this.wrapper = document.querySelector("[data-vote-wrapper]");
    this.voteUrl = this.wrapper.dataset.voteUrl;
    this.videoId = parseInt(this.wrapper.dataset.videoPk);

    this.starsContainer = this.wrapper.querySelector("[data-stars-container]");
    this.votedSuccessElement = this.wrapper.querySelector(
      "[data-vote-greetings]"
    );
    this.votedErrorElement = this.wrapper.querySelector(
      "[data-vote-already-done]"
    );
  }

  public async vote(): Promise<void> {
    const req = await fetch(this.voteUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "POST",
      body: JSON.stringify({ score: this.input.value, pk: this.videoId }),
    });

    if (req.ok) {
      await this.voted();
    } else {
      await this.error();
    }
  }

  private async voted(): Promise<void> {
    this.votedSuccessElement.style.display = "block";
    await sleep(3000);
    this.starsContainer.style.display = null;
    this.votedSuccessElement.style.display = "none";
  }

  private async error(): Promise<void> {
    this.votedErrorElement.style.display = "block";
    await sleep(3000);
    this.votedErrorElement.style.display = "none";
  }
}
