import { Page } from "./Page";
import PayWallInit from "../libs/PayWall";

export class MagazineDetailPage extends Page {
  public async start(): Promise<any> {
    await super.start();
    new PayWallInit();

    const my18passSelector = document.querySelector("[data-my18pass-cta]");
    my18passSelector?.addEventListener("click", (e: Event) => {
      (document.querySelector("my18pass-widget") as any).openDialog(e);
    });
  }
}
