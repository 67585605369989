export class PaginationAjax {
  container: HTMLElement;

  constructor(private showMoreButton: HTMLElement) {
    this.container = document.querySelector("[data-paginate-container]");
    if (!this.container) return;

    this.init();
  }

  private init(): void {
    this.showMoreButton.addEventListener("click", async (e: Event) => {
      e.preventDefault();
      await this.load();

      this.showMoreButton = document.querySelector("[data-show-more]");
      this.init();
    });
  }

  private async load(): Promise<void> {
    const req = await fetch(this.showMoreButton.dataset.showMore);
    if (req.ok) {
      this.setHtml(await req.text());
    }
  }

  private setHtml(rawContent: string): void {
    const html = new DOMParser().parseFromString(rawContent, "text/html");

    // set html results
    html.querySelectorAll("[data-paginate-item]").forEach((el: HTMLElement) => {
      this.container.append(el);
    });

    // replace button and pagination
    document
      .querySelector("[data-paginator-navbar]")
      .replaceWith(html.querySelector("[data-paginator-navbar]"));
    const newShowMoreButton: HTMLElement | null =
      html.querySelector("[data-show-more]");
    if (newShowMoreButton) {
      this.showMoreButton.replaceWith(newShowMoreButton);
      this.showMoreButton = newShowMoreButton;
    } else {
      this.showMoreButton.remove();
    }
  }
}
