import { toggleClass } from "@tawenda-npm/tawenda-utils";

export class DropDownHandler {
  private toggleClass: string;

  constructor(
    private dropdown: HTMLElement,
    private close_on_click = false,
    private update_button_value = false
  ) {
    this.toggleClass = this.dropdown.dataset.toggleClass;
    this.initEvents();
  }

  private initEvents(): void {
    if (!this.dropdown) {
      return;
    }

    const toggle = this.dropdown.querySelector("[data-toggle=dropdown]");

    if (toggle) {
      document.addEventListener(
        "click",
        (e: Event) => {
          if (
            !this.dropdown.contains(e.target as Node) ||
            this.close_on_click
          ) {
            this.close(e);
          }
        },
        false
      );

      this.dropdown
        .querySelector("[data-toggle=dropdown]")
        .addEventListener("click", (event: Event) => {
          this.isActive() ? this.close(event) : this.open();
          event.stopPropagation();
          event.preventDefault();
        });
    }
  }

  private isActive(): boolean {
    return this.dropdown.classList.contains(this.toggleClass);
  }

  private open(): void {
    if (!this.isActive()) toggleClass(this.dropdown);
  }

  private close(e: Event): void {
    if (this.isActive()) {
      toggleClass(this.dropdown);
      if (
        this.update_button_value &&
        this.dropdown.contains(e.target as Node)
      ) {
        const newValue: string = (e.target as HTMLElement).innerHTML;
        if (newValue) this.updateButtonValue(newValue);
      }
    }
  }

  private updateButtonValue(newValue: string) {
    const textToReplace: HTMLSpanElement = this.dropdown.querySelector(
      "[data-toggle=dropdown]"
    );
    if (textToReplace) textToReplace.innerHTML = newValue;
  }
}
