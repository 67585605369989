const contactForm = (): void => {
  const subjectField: HTMLElement | null =
    document.getElementById("id_recipient");
  const pressMsg: HTMLElement | null = document.querySelector(
    "[data-press-message]"
  );

  if (!subjectField || !pressMsg) {
    return;
  }

  subjectField.addEventListener("change", (): void => {
    if ((subjectField as HTMLSelectElement).value === "press") {
      pressMsg.style.display = "block";
    } else {
      pressMsg.style.display = "none";
    }
  });
};

export default contactForm;
