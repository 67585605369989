import { Page } from "./Page";
import { tns } from "tiny-slider/src/tiny-slider";
import carouselBanners from "../libs/CarouselBanners";

export class HomePage extends Page {
  public async start(): Promise<void> {
    const topCarouselBanners: HTMLElement =
      document.getElementById("top-carousel");
    if (topCarouselBanners) {
      tns({
        container: topCarouselBanners,
        items: 1,
        slideBy: "page",
        autoplay: true,
        mouseDrag: true,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
      });
    }

    carouselBanners();

    await super.start();
  }
}
