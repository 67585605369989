import { HomePage } from "./HomePage";
import { VideosListPage } from "./VideosListPage";
import { Page } from "./Page";
import { ContactPage } from "./ContactPage";
import { VideoDetailPage } from "./VideoDetailPage";
import { UserRegisterPage } from "./UserRegisterPage";
import { UserEditPage } from "./UserEditPage";
import { HowToDisableAdBlockPage } from "./AdBlockHowToDisable";
import { CheckDevicePage } from "./CheckDevicePage";
import { SearchResultsPage } from "./SearchResultsPage";
import { MagazinesListPage } from "./MagazinesListPage";
import { NewsListPage } from "./NewsListPage";
import { ScenesListPage } from "./ScenesListPage";
import { ActorsListPage } from "./ActorsListPage";
import { MagazineViewerPage } from "./MagazineViewerPage";
import { MagazineDetailPage } from "./MagazineDetailPage";

const initPages = (): void => {
  const body = document.querySelector("body");
  let page;

  switch (body.getAttribute("data-page")) {
    case "home":
      page = new HomePage();
      break;

    case "videos-list": {
      page = new VideosListPage();
      break;
    }

    case "video-detail": {
      page = new VideoDetailPage();
      break;
    }

    case "scenes-list": {
      page = new ScenesListPage();
      break;
    }

    case "search-results": {
      page = new SearchResultsPage();
      break;
    }

    case "magazines-list": {
      page = new MagazinesListPage();
      break;
    }

    case "magazine-detail": {
      page = new MagazineDetailPage();
      break;
    }

    case "magazine-viewer": {
      page = new MagazineViewerPage();
      break;
    }

    case "news-list": {
      page = new NewsListPage();
      break;
    }

    case "actors-list": {
      page = new ActorsListPage();
      break;
    }

    case "user-register": {
      page = new UserRegisterPage();
      break;
    }

    case "user-edit": {
      page = new UserEditPage();
      break;
    }

    case "how-to-disable-adblock": {
      page = new HowToDisableAdBlockPage();
      break;
    }

    case "check-device": {
      page = new CheckDevicePage();
      break;
    }

    case "contact": {
      page = new ContactPage();
      break;
    }
  }

  if (!page) {
    page = new Page();
  }
  page.start();
};

export default initPages;
