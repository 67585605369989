import { Page } from "./Page";
import { Tabs } from "@tawenda-npm/tawenda-utils/lib/tabsLib";

export class SearchResultsPage extends Page {
  public async start(): Promise<any> {
    const tabsWrapper: HTMLElement = document.querySelector(
      "[data-tabs-wrapper]"
    );
    if (tabsWrapper) {
      new Tabs(tabsWrapper);
    }
    await super.start();
  }
}
