import { Page } from "./Page";
import { PaginationAjax } from "../libs/PaginationAjax";
import { AjaxActors } from "../libs/ajaxActors";

export class VideosListPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    const showMoreButton = document.querySelector(
      "[data-show-more]"
    ) as HTMLElement;
    if (showMoreButton) {
      new PaginationAjax(showMoreButton);
    }

    const actorSelector: HTMLInputElement = document.getElementById(
      "actress_autocomplete"
    ) as HTMLInputElement;

    if (actorSelector) {
      new AjaxActors(actorSelector, document.getElementById("actress_list"));
    }
  }
}
