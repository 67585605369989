import { Page } from "./Page";
import { PdfViewer } from "../libs/PdfViewer";
import PayWallInit from "../libs/PayWall";

export class MagazineViewerPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    new PayWallInit();

    const viewerContainer: HTMLElement =
      document.getElementById("the-pdf-viewer");

    const pdf_viewer = new PdfViewer({
      pdfUrl: viewerContainer.dataset.pdfUrl,
      workerSrc: viewerContainer.dataset.workerSrc,
      container: viewerContainer,
      containerLoading: document.getElementById("loading"),
      containerOverlay: document.getElementById("overlay"),
      containerError: document.getElementById("error"),
      prevButton: document.getElementById("prev") as HTMLButtonElement,
      nextButton: document.getElementById("next") as HTMLButtonElement,
      targetPageNumGoTo: document.querySelector("[data-pagenum-goto]"),
      targetPageCount: document.querySelector("[data-page-count]"),
      isMobile: document.body.dataset.isMobile === "true",
      isExtract: viewerContainer.dataset.isExtract === "true",
      isSoft: viewerContainer.dataset.isSoft === "true",
    });

    await pdf_viewer.init();
  }
}
