import { Page } from "./Page";
import { PaginationAjax } from "../libs/PaginationAjax";

export class PageList extends Page {
  public async start(): Promise<any> {
    await super.start();

    const showMoreButton = document.querySelector(
      "[data-show-more]"
    ) as HTMLElement;
    if (showMoreButton) {
      new PaginationAjax(showMoreButton);
    }
  }
}
