import { Page } from "./Page";
import { PlayerHandler } from "../libs/Player";
import { Votes } from "../libs/Votes";
import { tns } from "tiny-slider";
import carouselBanners from "../libs/CarouselBanners";
import PayWallInit from "../libs/PayWall";

export class VideoDetailPage extends Page {
  public async start(): Promise<any> {
    const player = document.querySelector("[data-player-get-settings-url]");
    if (player) {
      const redirectTargetUrl = player.dataset.alternateVideoUrl;
      await new PlayerHandler(player as HTMLElement).init();
      if (redirectTargetUrl) {
        player.addEventListener("ended", () => {
          window.location.href = redirectTargetUrl;
        });
        setTimeout(() => {
          window.location.href = redirectTargetUrl;
        }, 10000);
      }
    }

    await super.start();

    PayWallInit();
    carouselBanners();

    const my18passSelector = document.querySelector(
      "[data-my18pass-free-video]"
    );
    my18passSelector?.addEventListener("click", (e: Event) => {
      (document.querySelector("my18pass-widget") as any).openDialog(e);
    });

    document
      .querySelectorAll("[data-stars-container] label")
      .forEach((labelElement: HTMLLabelElement) => {
        labelElement.addEventListener("click", async (e: Event) => {
          e.preventDefault();
          const inputElement = document.getElementById(
            labelElement.htmlFor
          ) as HTMLInputElement;
          if (inputElement) await new Votes(inputElement).vote();
        });
      });

    const actorsSlider: HTMLElement = document.getElementById("actors-slider");
    if (actorsSlider) {
      tns({
        container: actorsSlider,
        items: 2,
        slideBy: 1,
        autoplay: true,
        mouseDrag: true,
        touch: true,
        autoplayTimeout: 4000,
        rewind: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        gutter: 10,
        lazyload: true,
        lazyloadSelector: ".lazyload",
        responsive: {
          768: {
            items: 3,
          },

          920: {
            items: 5,
          },

          1280: {
            items: 5,
          },
        },
      });
    }
  }
}
